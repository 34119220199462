.alert {
  @apply rounded p-3 w-full;
}

.alert.info {
  @apply bg-blue-200 text-blue-800;
}

:global(.dark) .alert.info {
  @apply bg-blue-800 text-blue-200;
}

.alert.danger {
  @apply bg-red-200 text-red-800;
}

:global(.dark) .alert.danger {
  @apply bg-red-800 text-red-200;
}

.alert.success {
  @apply bg-green-200 text-green-800;
}

:global(.dark) .alert.success {
  @apply bg-green-800 text-green-200;
}
